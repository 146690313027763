import Swal from "sweetalert2";

export default {
  name: "ForCRUDFunction",
  computed: {
    isEdit() {
      return this.$route.path.includes("edit");
    },
  },
  methods: {
    getHospitalData(id) {
      this.$requestService.get(
        this.$apiEndpoints.hospitals + `/${id}`,
        {},
        this.getHospitalDataSuccess,
      );
    },
    defineUSerCriteria(tab) {
      switch (tab) {
        case "treatingPhysicians":
          return "PHYSICIAN";
        case "dataManager":
          return "MANAGER";
        default:
          break;
      }
    },
    fetchData(customPayload = {}) {
      this.config.loading = true;

      // define endpoint
      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      let payload = {};

      // if endpoint is users
      if (ep === "users") {
        payload = {
          // default criteria
          criteria: {
            field: null,
            op: "and",
            value: null,
            of: [
              {
                field: "role",
                op: "is",
                value: this.defineUSerCriteria(tab),
              },
            ],
          },
          paging: {
            ...customPayload.paging,
          },
        };
      } else if (
        (ep === "hospitals" ||
          ep === "summaryPatientView" ||
          ep === "secondopinions") &&
        customPayload.criterias
      ) {
        if (customPayload.criterias.op === 'or') {
          payload = {
            criteria: { ...customPayload.criterias },
            paging: { ...customPayload.paging }
          }
          if (payload.criteria.of[0].value === "") delete payload.criteria;
        } else {
          // confition on hospital tab, only one object criteria
          payload = {
            criteria: {
              field: customPayload.criterias[0].field,
              op: "is",
              value: customPayload.criterias[0].value || "",
            },
            paging: {
              ...customPayload.paging,
            },
          };

          if (payload.criteria.value === "") delete payload.criteria;
        }
      }

      // if there is changes on page number, use default here
      if (customPayload && !customPayload.paging) {
        // condition accepted on page load
        payload = {
          ...payload,
          ...{
            paging: {
              page: 0,
              size: 5,
            },
          },
        };
      } else {
        // usually on search
        payload = {
          ...payload,
          paging: {
            page: customPayload.paging.page,
            size: customPayload.paging.size || 5
          },
        };
      }

      // if there is additional criterias
      if (ep === "users" && customPayload.criterias) {
        if (customPayload.criterias[0].value) {
          payload.criteria.of = [
            ...payload.criteria.of,
            ...customPayload.criterias,
          ];
        } else {
          // move "of" criteria to only one object
          payload.criteria.field = "role";
          payload.criteria.op = "is";
          payload.criteria.value = this.defineUSerCriteria(tab);

          delete payload.criteria.of;
        }
      }

      this.$requestService
        .post(
          this.$apiEndpoints[ep] + "/search",
          payload,
          this.handleFetchListSuccess,
          this.handleFetchListFailed
        )
        .then(() => {
          this.config.loading = false;
        });
    },
    handleFetchDataFailed(e) {
      this.$vToastify.error(e.message);
    },
    submitCreate(payload) {
      this.config.formLoading = true;

      const method = this.isEdit ? "put" : "post";

      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      const url = this.isEdit
        ? this.$apiEndpoints[ep] + `/${payload.id}`
        : this.$apiEndpoints[ep];

      this.$requestService[method](
        url,
        payload,
        this.handleSubmitSuccess,
        this.handleSubmitFailed
      ).then(() => {
        this.config.formLoading = false;
      });
    },
    handleSubmitSuccess() {
      // this.$vToastify.success();
      const tab = this.$route.params.tab;
      this.$router.push({
        name: "summary",
        params: { tab },
      });
    },
    handleSubmitFailed(e) {
      if (e.response.status === 400 || e.response.status === 422) {
        this.config.errors = e.response.data.errors;
      } else {
        this.$vToastify.error(e.message);
      }
    },
    deleteConfirm(row) {
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to delete this row ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
        customClass: {
          confirmButton: "confirmButton",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(row.id);
        }
      });
    },
    deleteData(id) {
      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      this.$requestService.delete(
        this.$apiEndpoints[ep] + "/" + id,
        {},
        this.handleDeleteSucess,
        this.handleDeleteFailed
      );
    },
    handleDeleteSucess() {
      this.$vToastify.success("Successfully Deleted");
      this.fetchData();
    },
    handleDeleteFailed() {
      this.$vToastify.error("Oops, something wromg");
    },
    populateForm() {
      const { tab } = this.$route.params;
      const ep =
        tab === "hospitals" ||
        tab === "summaryPatientView" ||
        tab === "secondopinions"
          ? tab
          : "users";

      this.$requestService.get(
        this.$apiEndpoints[ep] + `/${this.$route.params.id}`,
        {},
        this.handleGetSuccess,
        this.handleGetFailed
      );
    },
    handleGetSuccess(response) {
      this.config.formEdit = { ...response.data };
    },
  },
};
